import { replace } from 'connected-react-router';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import _first from 'lodash/first';
import _get from 'lodash/get';

import {
  hideLoader,
  showLoader,
  hideDrawer,
  showSnackbar,
  showTransparentLoader,
} from 'containers/store';

import messages from 'components/pages/AnonymousUserData/AnonymousUserData.messages';

export const initialState = {
  isLoadedPage: true,
  initialValues: {
    gender: null,
    year: '',
    month: '',
    nickname: '',
  },
  shouldEnterNickname: false,
  isNicknameModalOpen: false,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'ANONYMOUS_USER_DATA/LOAD_PAGE_SUCCESS',
  SET_NICKNAME_DIALOG: 'ANONYMOUS_USER_DATA/SET_NICKNAME_DIALOG',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        isNicknameModalOpen: false,
        shouldEnterNickname: action.enforceUseUniqueNickname,
      };
    }

    case actionTypes.SET_NICKNAME_DIALOG: {
      return {
        ...state,
        isNicknameModalOpen: action.isOpen,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (enforceUseUniqueNickname) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  enforceUseUniqueNickname,
});

const checkCriteria = (id, patientId, sex, birthDate) => (dispatch) => ApiManager.request(
  'get', dispatch, `session_patient_groups/${id}/patients/${patientId}/matching_criteria?sex=${sex}&birthDate=${birthDate}`,
);

const checkNickname = (patientId, body) => (dispatch) => ApiManager.request('post', dispatch, `patients/${patientId}/checkIfNicknameIsUniqueInClinic`, body);

const saveData = (id, body) => (dispatch) => ApiManager.request('put', dispatch, `patients/${id}`, body);

export const setNicknameDialog = (isOpen) => ({
  type: actionTypes.SET_NICKNAME_DIALOG,
  isOpen,
});

export const onSetNicknameDialog = (isOpen) => (dispatch) => {
  dispatch(setNicknameDialog(isOpen));
};

export const onSubmit = (values, methods, intl) => async (dispatch, getStore) => {
  const patientId = getStore().Global.sessionPatient.patient.id;
  const shouldEnterNickname = getStore().AnonymousUserData.shouldEnterNickname;
  const id = _get(_first(getStore().Global.groupSurveys), 'session_patient_group_id', null);
  const sex = values.gender === 'male';
  const birthDate = `${values.year}-${values.month}-01`;

  dispatch(showTransparentLoader());

  try {
    await dispatch(checkCriteria(id, patientId, sex, birthDate));
  } catch (error) {
    if (!_get(error, 'error.success', false)) {
      dispatch(hideLoader());
      methods.setFieldError('year', intl.formatMessage(messages.yearNotMatching));
      methods.setFieldError('month', intl.formatMessage(messages.monthNotMatching));
    }

    return;
  }

  if (shouldEnterNickname) {
    const isNicknameUniqueData = await dispatch(checkNickname(patientId, {
      data: {
        unique_nickname: values.nickname,
      },
    }));

    if (isNicknameUniqueData.is_unique) {
      dispatch(hideLoader());
      dispatch(setNicknameDialog(true));

      return;
    }
  }

  const requestBody = {
    data: {
      sex,
      birth_date: birthDate,
      unique_nickname: shouldEnterNickname ? values.nickname : undefined,
    },
  };

  dispatch(saveData(patientId, requestBody)).then(() => {
    dispatch(hideLoader());
    dispatch(setNicknameDialog(false));
    dispatch(replace('/researchStart'));
  }).catch(() => {
    dispatch(hideLoader());
    dispatch(showSnackbar(snackbarMessages.globalError));
  });
};

export const confirmNicknameAndSave = (values) => (dispatch, getStore) => {
  const patientId = getStore().Global.sessionPatient.patient.id;
  const sex = values.gender === 'male';
  const birthDate = `${values.year}-${values.month}-01`;
  const requestBody = {
    data: {
      sex,
      birth_date: birthDate,
      unique_nickname: values.nickname,
    },
  };

  dispatch(showTransparentLoader());

  dispatch(saveData(patientId, requestBody)).then(() => {
    dispatch(hideLoader());
    dispatch(replace('/researchStart'));
  }).catch(() => {
    dispatch(hideLoader());
    dispatch(showSnackbar(snackbarMessages.globalError));
  });
};

export const loadPageData = () => (dispatch, getStore) => {
  const enforceUseUniqueNickname = getStore().Global.sessionPatient.enforceUseUniqueNickname;
  dispatch(hideDrawer());
  dispatch(showLoader());
  dispatch(loadPageSuccess(enforceUseUniqueNickname));
  dispatch(hideLoader());
};
